:root {
	--white: #ffffff;
	--blue: #125e9f;
	--blue2: #5276a0;
	--blue3: #6c87c6;
	--grey: #5f5f5f;
	--green: #79b256;
	--bigFontSize: 40px;
	--fontFamily: Helvetica Neue, serif;
	--header-height: 56px;
	--sidebar-width: 80px;
	--panel-column-width: 200px;
	--panel-column-gap: 2rem;
}

body {
	font-family: "Roboto", serif;
	font-size: 16px;
}
